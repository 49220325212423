const BASE_PATHS = {
  homepage: "/",
  landing_page: "/page/",
  "title-paragraphs": "/",
  blog_post: "/blog/",
  custom_tag: "/blog/tags/",
  category: "/blog/category/",
  pricing: "/",
  candidates_v2: "/candidates/",
  blog_home: "/",
  recruiters_v2: "/recruiters/",
  about: "/",
  blog_posts_pagination: "/blog/posts/",
}

module.exports = {
  BASE_PATHS,
}
