const { BASE_PATHS } = require("./base-paths")

const replaceAll = (str, mapObj) => {
  const re = new RegExp(Object.keys(mapObj).join("|"), "gi")
  return (str = str.replace(re, function (matched) {
    return mapObj[matched]
  }))
}

const clearLanguageSignature = value => {
  return value && value.replace("_de", "")
}

const mapPath = {
  _de: "", // some german documents have uid ending with _de that has to be removed
  // TODO: remove _de workaround on the cms since no needed anymore
  _: "/", // recruiters_terms -> recruiters/terms
}
const localizePath = (path, locale) =>
  locale === "de-de" ? `/de${path}` : path

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
const addTrailingSlash = path =>
  path[path.length - 1] === "/" ? path : `${path}/`

function getPath({ docType, lang, uid }) {
  const isIndexPage = ["homepage", "candidates_v2", "recruiters_v2"].includes(
    docType,
  )
  let cleanUid = isIndexPage ? "" : clearLanguageSignature(uid, mapPath) // blog-post-uid_de -> blog-post-uid
  if (["pricing", "title-paragraphs"].includes(docType)) {
    cleanUid = replaceAll(cleanUid, mapPath) // pricing_company -> pricing/company
  }
  const basePath = BASE_PATHS[docType]
  const localizedPath = localizePath(`${basePath}${cleanUid}`, lang)
  return addTrailingSlash(localizedPath)
}

module.exports = {
  replaceAll,
  mapPath,
  localizePath,
  asyncForEach,
  clearLanguageSignature,
  getPath,
  addTrailingSlash,
}
